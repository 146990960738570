import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../../elements/Box';
import Text from '../../../elements/Text';
import Heading from '../../../elements/Heading';
import FeatureBlock from '../../../components/FeatureBlock';
import { Service } from '../../../data/Saas';
import { mobile } from '../../../data/Saas';
import Container from '../../../components/UI/Container';
import ServiceSectionWrapper from './service.style';
import Image from '../../../elements/Image';
// import image5 from '../../../assets/image/saas/mobile/phone-titantasks.png';
import image5 from '../../../assets/image/saas/mobile/giffTitan.webm';
import image6 from '../../../assets/image/saas/mobile/giffTitan_en.webm';
import Icon from 'antd/lib/icon';
import Button from 'antd/lib/button';

const ServiceSection = ({
  row,
  row1,
  row1Wrap,
  col,
  col1,
  col2,
  colImg,
  colImg2,
  colImg3,
  sectionHeader,
  sectionHeader2,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  titleMobile,
  titleMobile2,
  featureTitle2,
  featureTitle3,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
  blockWrapperStyleRight,
  blockWrapperStyleLeft,
  funcCol,
  funcCol2,
  funcCol3,
  funcColLeft,
  funcColRight,
  featureDescription2,
  ...props
}) => (
  <ServiceSectionWrapper ref={props.ref} id="feature_section">
    <Container>
      <Box {...sectionHeader}>
        <Text content={props.formatMessage({ id: 'serviceSection.ourFeatures', defaultMessage: 'features' })} {...sectionSubTitle} style={{ textTransform: 'uppercase' }}/>
        <Heading content={props.formatMessage({ id: 'serviceSection.subTitle', defaultMessage: 'subTitle' })} {...sectionTitle} />
      </Box>
      <Box className="row" {...row}>
        {Service.map((feature, index) => (
          <Box
            className="col saasServiceBlock"
            {...col}
            key={index}
            onClick={() => {
            if (feature.path !== '/') return props.history.push(feature.path);
          }}>
            <FeatureBlock
              icon={<i className={feature.icon} />}
              wrapperStyle={blockWrapperStyle}
              iconStyle={iconStyle}
              contentStyle={contentStyle}
              title={<Heading content={props.formatMessage({ id: `serviceSection.${feature.title}`, defaultMessage: feature.title })} {...featureTitle} />}
              description={
                <div>
                  <Text content={props.formatMessage({ id: `serviceSection.${feature.description}`, defaultMessage: feature.description })} {...featureDescription} />
                  <a >Saiba mais...
                  </a>
                </div>
                }
              className="saasService"
              />
          </Box>
          ))}
        <div style={{
          display: 'flex', justifyContent: 'center', width: '100%', marginTop: 40,
        }}>
          <Button
            className="button-more-info"
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: 'auto',
              borderRadius: 4,
              color: '#5268db',
              backgroundColor: '#fff',
              border: '1px solid #c9c9c9',
            }}
            onClick={() => props.history.push('/talk-to-us')}
          >
            <div
              style={{
                padding: 10,
                paddingRight: 40,
                paddingLeft: 40,
                fontSize: 16,
              }}
              dangerouslySetInnerHTML={{ __html: props.formatMessage({ id: 'subscriptions.needMore', defaultMessage: 'features' }) }}
            />
          </Button>
        </div>
      </Box>
    </Container>
    <Container>
      <Box {...sectionHeader2}>
        <Text content={props.formatMessage({ id: 'workFlowSection.title', defaultMessage: 'main services' })} {...sectionSubTitle} style={{ textTransform: 'uppercase' }}/>
        <Heading content={props.formatMessage({ id: 'workFlowSection.subTitle2', defaultMessage: 'main services subtitle' })} {...sectionTitle} />
      </Box>
      <Box className="row" {...row}>
        <Box className="colImg" {...colImg3} style={{ display: 'flex' }}>
          <video
            src={props.language === 'pt' ? image5 : image6}
            autoPlay
            muted
            style={{
                display: 'block',
                maxWidth: '100%',
                height: 'auto',
                boxSizing: 'border-box',
                margin: 0,
              }}
          />
        </Box>
        <Box className="col bullet-points" {...funcCol3}>
          <FeatureBlock
            wrapperStyle={blockWrapperStyleLeft}
            iconStyle={iconStyle}
            contentStyle={contentStyle}
            title={<Heading content={props.formatMessage({ id: 'mobile.title' })} {...titleMobile} />}
            description={
              <div>
                <div style={featureDescription} dangerouslySetInnerHTML={{ __html: props.formatMessage({ id: 'mobile.text' }) }} />
                <Heading content={props.formatMessage({ id: 'mobile.title2' })} {...titleMobile2} />
                <Text content={props.formatMessage({ id: 'mobile.text2' })} {...featureDescription} />
                {mobile.map((item, index) => (
                  <FeatureBlock
                    key="feature_point-1"
                    icon={<Icon type="arrow-right" />}
                    iconPosition="left"
                    title={<div style={featureDescription} dangerouslySetInnerHTML={{ __html: props.formatMessage({ id: `mobile.${item.text}` }) }} />}
                  />
                ))}
                <div style={featureDescription} dangerouslySetInnerHTML={{ __html: props.formatMessage({ id: 'mobile.text3' }) }} />
                <div style={featureDescription} dangerouslySetInnerHTML={{ __html: props.formatMessage({ id: 'mobile.text4' }) }} />
              </div>
              }
            className="saasFeature"
          />
        </Box>
      </Box>
      {/* <Box className="row" {...row1}>
        <Box className="colImg" {...colImg} style={{ display: 'flex' }}>
          <Image src={image1} />
        </Box>
        <Box className="col" {...funcColLeft}>
          <FeatureBlock
            wrapperStyle={blockWrapperStyleRight}
            iconStyle={iconStyle}
            contentStyle={contentStyle}
            title={<Heading content={props.formatMessage({ id: 'mobile.title' })} {...featureTitle} />}
            description={
              <div style={featureDescription} dangerouslySetInnerHTML={{ __html: props.formatMessage({ id: 'mobile.text' }) }} />
              }
            className="saasFeature"
          />
        </Box>
      </Box> */}
    </Container>
  </ServiceSectionWrapper>
);

// ServiceSection style props
ServiceSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// ServiceSection default style
ServiceSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['50px', '50px', '50px', '80px'],
  },
  sectionHeader2: {
    mb: ['50px', '50px', '50px', '80px'],
    mt: ['50px', '50px', '50px', '80px'],
  },
  blockWrapperStyleLeft: {
    p: ['30px', '40px', '20px', '20px'],
  },
  blockWrapperStyleRight: {
    p: ['30px', '20px', '20px', '40px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  titleMobile: {
    textAlign: 'left',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '6',
  },
  titleMobile2: {
    textAlign: 'left',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '6',
    mt: '10',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  row1: {
    flexBox: true,
    flexWrap: 'wrap-reverse',
    mt: ['50px', '50px', '50px', '80px'],
  },
  row1Wrap: {
    flexBox: true,
    flexWrap: 'wrap',
    mt: ['50px', '50px', '50px', '80px'],
  },
  // feature col default style
  col2: { width: ['100%', '50%', '50%'] },
  funcCol2: {
    width: ['100%', '50%', '50%'],
    pl: ['0px', '40px'],
  },
  col1: {
    width: ['100%'],
  },
  colImg2: {
    width: ['100%', '50%', '50%'],
    pt: ['30px', '10px', '0px', '0px'],
    pb: ['30px', '10px', '0px', '0px'],
    pl: ['30px', '10px', '0px', '0px'],
    pr: ['30px', '10px', '0px', '0px'],
  },
  funcCol3: {
    width: ['100%', '100%', '100%', '66%'],
    pl: ['0px', '40px'],
  },
  colImg3: {
    width: ['100%', '100%', '100%', '33%'],
    pt: ['30px', '10px', '0px', '0px'],
    pb: ['30px', '10px', '0px', '0px'],
    pl: ['30px', '10px', '0px', '0px'],
    pr: ['30px', '10px', '0px', '0px'],
  },
  colImg: {
    width: ['100%', '25%'],
    pt: ['30px', '10px', '0px', '0px'],
    pb: ['30px', '10px', '0px', '0px'],
    pl: ['30px', '10px', '0px', '0px'],
    pr: ['30px', '10px', '0px', '0px'],
  },
  funcColLeft: {
    width: ['100%', '75%', '75%', '75%'],
    pl: ['0px', '40px'],
  },
  funcColRight: {
    width: ['100%', '75%', '75%', '75%'],
    pr: ['0px', '70px'],
  },
  funcCol: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    width: ['100%', '75%', '75%', '75%'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '80px'],
    height: ['70px', '80px'],
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: '30px',
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
    mt: '-5px',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    letterSpacing: '-0.020em',
    mb: '10px',
  },
  featureTitle2: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    letterSpacing: '-0.020em',
    mb: '10px',
    mt: '40px',
  },
  featureTitle3: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
  featureDescription2: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
    pr: ['0px', '70px'],
  },
};

export default ServiceSection;
