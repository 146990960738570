import React from 'react';
import PropTypes from 'prop-types';

const Loading = props => (
  <div style={{
        width: '100%',
        height: '100%',
        background: `RGBA(255,255,255,${props.opacity})`,
        zIndex: '10',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}
    >
    {/* <div className="cp-spinner cp-morph"></div> */}
    <div className="ant-spin ant-spin-spinning" style={{ top: '50%', position: 'fixed' }}>
      <span className="ant-spin-dot ant-spin-dot-spin">
        <i className="ant-spin-dot-item" />
        <i className="ant-spin-dot-item" />
        <i className="ant-spin-dot-item" />
        <i className="ant-spin-dot-item" />
      </span>
    </div>
  </div>
);

Loading.defaultProps = {
  opacity: 1,
};

Loading.propTypes = {
  opacity: PropTypes.number,
};

export default Loading;
