import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import LocaleEN from 'react-intl/locale-data/en';
import LocalePT from 'react-intl/locale-data/pt';
import { Router as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import messagesPT from './translations/pt.json';
import messagesEN from './translations/en.json';

addLocaleData([...LocaleEN, ...LocalePT]);
const messages = {
  pt: messagesPT,
  en: messagesEN,
};

const navigatorLanguage = navigator.language || navigator.userLanguage;
let language = 'en';

ReactGA.initialize('UA-153480142-1');

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  console.log(window.location.pathname);
});


switch (navigatorLanguage) {
  case ('pt'):
    language = 'pt';
    break;
  case ('pt-PT'):
    language = 'pt';
    break;
  case ('pt-BR'):
    language = 'pt';
    break;
  default:
    language = 'en';
    break;
}

// Função para construção de objetos em translations
function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

ReactDOM.render(
  <IntlProvider key={language} locale={language} messages={flattenMessages(messages[language])} >
    <Router history={history}>
      <App language={language}/>
    </Router>
  </IntlProvider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
