import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
/* import Icon from 'react-icons-kit'; */
import Box from '../../../elements/Box';
import Text from '../../../elements/Text';
import Heading from '../../../elements/Heading';
import Container from '../../../components/UI/Container';
import List from 'antd/lib/list';
import Card from 'antd/lib/card';
import Button from 'antd/lib/button';

const PricingSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  buttonStyle,
  buttonFillStyle,
  listContentStyle, ...props
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 500);
  });

  function renderCards() {
    const htmlContent = [];
    const planColors = {
      free: '#2595d4',
      bronze: '#f55767',
      silver: '#999999',
      gold: '#e9b600',
      platinum: '#2595d4',
    };
    const plansColorsGradient = {
      free: 'linear-gradient(31deg, rgb(109, 185, 228) 0%, rgb(37, 149, 212) 100%)',
      bronze: 'linear-gradient(31deg, rgb(255, 147, 158) 0%, rgb(245, 87, 103) 100%)',
      silver: 'linear-gradient(31deg, rgb(210, 210, 210) 0%, rgb(153, 153, 153) 100%)',
      gold: 'linear-gradient(31deg, rgb(255, 224, 112) 0%, rgb(233, 182, 0) 100%)',
      platinum: 'linear-gradient(31deg, rgb(109, 185, 228) 0%, rgb(37, 149, 212) 100%)',
    };
    const plansColorsGradientOpacity = {
      free: 'linear-gradient(31deg, rgba(109, 185, 228, 0.5) 0%, rgba(37, 149, 212, 0.5) 100%)',
      bronze: 'linear-gradient(31deg, rgb(255, 147, 158) 0%, rgb(245, 87, 103) 100%)',
      silver: 'linear-gradient(31deg, rgb(210, 210, 210) 0%, rgb(153, 153, 153) 100%)',
      gold: 'linear-gradient(31deg, rgb(255, 224, 112) 0%, rgb(233, 182, 0) 100%)',
      platinum: 'linear-gradient(31deg, rgba(109, 185, 228, 0.5) 0%, rgba(37, 149, 212, 0.5) 100%)',
    };
    const plans = [
      {
        id: '1',
        designation: 'free',
        report_limit: 250,
        map_limit: 500,
        user_limit: 5,
        store_limit: 1,
        storage_limit: "0.5 GB",
        created_at: '2019-06-27 18:24:34',
        modified_at: '2019-08-26 15:45:05',
        is_deleted: 0,
        number: 3,
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 0,
        amount_decimal: '3000',
        billing_scheme: 'per_unit',
        created: 1575308864,
        currency: 'eur',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'free',
        product: 'prod_GFuZc81FDDxcFz',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed',
      },
      {
        id: '2',
        designation: 'bronze',
        report_limit: 1000,
        map_limit: 1000,
        user_limit: 10,
        store_limit: 1,
        storage_limit: "1 GB",
        created_at: '2019-06-27 18:24:34',
        modified_at: '2019-08-26 15:45:05',
        is_deleted: 0,
        number: 3,
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 3000,
        amount_decimal: '3000',
        billing_scheme: 'per_unit',
        created: 1575308864,
        currency: 'eur',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'bronze',
        product: 'prod_GFuZc81FDDxcFz',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed',
      },
      {
        id: '3',
        designation: 'silver',
        report_limit: 2500,
        map_limit: 2500,
        user_limit: 25,
        store_limit: 2,
        storage_limit: "2 GB",
        created_at: '2019-06-27 18:28:01',
        modified_at: '2019-08-26 15:45:06',
        is_deleted: 0,
        number: 4,
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 6000,
        amount_decimal: '6000',
        billing_scheme: 'per_unit',
        created: 1575309206,
        currency: 'eur',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'silver',
        product: 'prod_GFudsw9h1HAtrc',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed',
      },
      {
        id: '4',
        designation: 'gold',
        report_limit: 4000,
        map_limit: 4000,
        user_limit: 50,
        store_limit: 2,
        storage_limit: "5 GB",
        created_at: '2019-06-27 18:29:11',
        modified_at: '2019-08-26 15:45:07',
        is_deleted: 0,
        number: 5,
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 9000,
        amount_decimal: '9000',
        billing_scheme: 'per_unit',
        created: 1575309262,
        currency: 'eur',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'gold',
        product: 'prod_GFudwDcjXHhKbo',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed',
      },
      /* {
        id: '6',
        designation: 'platinum',
        report_limit: 7500,
        map_limit: 7500,
        user_limit: 75,
        store_limit: 5,
        created_at: '2019-06-27 18:30:38',
        modified_at: '2019-08-26 15:45:08',
        is_deleted: 0,
        number: 6,
        object: 'plan',
        active: true,
        aggregate_usage: null,
        amount: 12000,
        amount_decimal: '12000',
        billing_scheme: 'per_unit',
        created: 1575309335,
        currency: 'eur',
        interval: 'month',
        interval_count: 1,
        livemode: true,
        metadata: {},
        nickname: 'platinum',
        product: 'prod_GFueYUXm9BEgup',
        tiers: null,
        tiers_mode: null,
        transform_usage: null,
        trial_period_days: null,
        usage_type: 'licensed',
      }, */
    ];

    const enterprise = ['Integração ERP', 'Apoio Dedicado e Prioritário', 'Consultoria de Arranque', 'Servidor Interno', 'Formação'];

    for (let i = 0; i < plans.length; i++) {
      htmlContent.push(
        <div className={plans[i].nickname === 'bronze' ? 'flash-button-free' : ''}>
          <Card
            hoverable
            style={{
                minWidth: 250,
                margin: plans[i].nickname === 'bronze' ? 0 : 15,
                borderRadius: 4,
              }}
            >
            <div
              style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: '432px',
                }}
              onClick={() => (window.location.href = ('http://cronus.titantasks.com/#/register'))}
            >
              <div
                style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '0 -15px',
                      backgroundColor: planColors[plans[i].designation],
                      backgroundImage: plansColorsGradient[plans[i].designation],
                      padding: '10px',
                      borderRadius: '4px 4px 0px 0px',
                    }}
                  >
                <div style={{ color: 'white', fontSize: '30px', textTransform: 'capitalize' }}>
                  {plans[i].nickname}
                </div>
              </div>
              <div
                style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'rgba(0, 0, 0, 0.65)',
                        }}
                    >
                <div style={{ fontSize: '30px', marginBottom: '-5px' }}>
                  { props.language.includes('en') ? ( '$' + (plans[i].amount * 0.01)) : ( '€' + (plans[i].amount * 0.01)) }
                </div>
                <div style={{ fontSize: '15px' }}>
                  {props.formatMessage({ id: 'subscriptions.plustax/month' })}
                </div>
              </div>
              <div>
                <List
                  itemLayout="horizontal"
                  dataSource={[
                          {
                            id: 'storage_limit',
                            icon: 'iconfont icon-map',
                          },
                          {
                            id: 'map_limit',
                            icon: 'iconfont icon-map',
                          },
                          {
                            id: 'report_limit',
                            icon: 'iconfont icon-copy',
                          },
                          {
                            id: 'user_limit',
                            icon: 'iconfont icon-user',
                          },
                        ]}
                  renderItem={item => (
                    <List.Item>
                      <div
                        style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '-4px',
                                  }}
                              >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i
                            style={{ marginRight: '5px', marginTop: '-2px', fontSize: '18px' }}
                            className={`iconfont ${item.icon}`}
                                  />
                          <div style={{ textTransform: 'capitalize', fontSize: '16px' }}>
                            {props.formatMessage({ id: `subscriptions.${item.id}` })}
                          </div>
                        </div>
                        <div style={{ marginLeft: '3px', fontWeight: 600 }}>{plans[i][item.id]}</div>
                      </div>
                    </List.Item>
                        )}
                    />
              </div>
              <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: 20,
                  paddingTop: 10,
                }}>
                {plans[i].nickname === 'free' ?
                  <Button
                    className={`${'flash-button'}`}
                    style={{
                      borderColor: 'transparent',
                      backgroundColor: planColors[plans[i].designation],
                      backgroundImage: plansColorsGradient[plans[i].designation],
                      color: 'white',
                    }}
                    onClick={() => (window.location.href = ('http://cronus.titantasks.com/#/register'))}
                >
                    {props.formatMessage({ id: 'subscriptions.choose' })}
                  </Button>
                    :
                  <Button
                    className={`${'flash-button'}-${plans[i].nickname}`}
                    style={{
                          borderColor: 'transparent',
                          backgroundColor: planColors[plans[i].designation],
                          backgroundImage: plansColorsGradient[plans[i].designation],
                          color: 'white',
                        }}
                    onClick={() => (window.location.href = ('http://cronus.titantasks.com/#/register'))}
                    >
                    {props.formatMessage({ id: 'subscriptions.choose' })}
                  </Button>
                }
              </div>
            </div>
          </Card>
        </div>,
      );
    }


    return (
      <div>
        <div
          className="pricing-cards"
          style={{
               display: 'flex',
               marginBottom: '20px',
             }}>
          {htmlContent}
        </div>
        <div style={{
 display: 'flex', justifyContent: 'center', width: '100%', marginTop: 40,
}}>
          <Button
            className="button-more-info"
            style={{
                display: 'flex',
                justifyContent: 'center',
                height: 'auto',
                borderRadius: 4,
                color: '#5268db',
                backgroundColor: '#fff',
              border: '1px solid #c9c9c9',
              }}
            onClick={() => props.history.push('/explore-more')}
          >
            <div
              style={{
                padding: 10,
                paddingRight: 40,
                paddingLeft: 40,
                fontSize: 16,
              }}
              dangerouslySetInnerHTML={{ __html: props.formatMessage({ id: 'bannerSection.exploreMore', defaultMessage: 'explore more' }) }}
             />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text content={props.formatMessage({ id: 'subscriptions.firstTitle', defaultMessage: 'features' })} {...secText} />
          <Heading content={props.formatMessage({ id: 'subscriptions.title', defaultMessage: 'subTitle' })} {...secHeading} />
        </Box>
        <Box {...row}>
          <div style={{ margin: 'auto', maxWidth: '100%', overflow: 'auto' }}>
            {renderCards()}
          </div>
        </Box>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['60px', '80px', '80px', '80px', '120px'],
    pb: ['20px', '20px', '20px', '80px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  secTitleWrapper: {
    mb: ['50px', '75px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primary',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
  },
};

export default PricingSection;
