import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon } from 'antd';
import emailjs from 'emailjs-com';
import '../../assets/css/antdOverride.css';
// eslint-disable-next-line import/first
import 'antd/dist/antd.css';
import Box from '../../elements/Box';
import Text from '../../elements/Text';
import Heading from '../../elements/Heading';
import Container from '../../components/UI/Container';
import Button from '../../elements/Button';
import Image from '../../elements/Image';
import ExploreFeaturesWrapper from './talkToUs.style';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select';
import Radio from 'antd/lib/radio';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import ramalhos from '../../assets/image/saas/companies/ramalhos.webp';
import marjos from '../../assets/image/saas/companies/marjos.webp';
import longoplano from '../../assets/image/saas/companies/longoplano.webp';
import safira from '../../assets/image/saas/companies/safira.webp';
import '../../index.css';

const { Option } = Select;
const { SubMenu } = Menu;

class TalkToUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKey: 'createTask',
      email: '',
      users_number: '',
      software: '',
      checkedValue: '',
      otherSoftware: '',
      loading: false,
      companyName: '',
      firstName: '',
      lastName: '',
      moreInfo: '',
      selectedSoftware: '',
    };

    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeText(key, value) {
    this.setState({
      [key]: value.target.value,
    });
  }

  handleOnChange(e) {
    this.setState({
      checkedValue: e.target.value,
    });
  }

  handleChangeDropdown(value) {
    console.log(`Selected: ${value}`);
    this.setState({ selectedSoftware: value });
  }

  handleSubmit() {
    this.setState({ loading: true });
    if (this.state.email.length > 5 && this.state.users_number.length > 0 && this.state.selectedSoftware.length > 0) {
      let software = this.state.selectedSoftware;
      if (this.state.selectedSoftware === this.props.formatMessage({ id: 'other' })) {
        software = this.state.otherSoftware;
      }

      const templateParams = {
        subject: 'Talk To Us - TitanTasks',
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        from: this.state.email,
        company_name: this.state.companyName,
        users_number: this.state.users_number,
        software,
        more_info: this.state.moreInfo,
      };

      emailjs.send('smtp_server', 'talk_to_us', templateParams, 'user_f6Wtg7Xhii9zdEtmQoVKv')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          this.setState({ loading: false });
        }, (error) => {
          console.log('FAILED...', error);
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
      console.log('hhhh');
    }
  }

  render() {
    const { formatMessage, colForm, colText } = this.props;

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    const softwareERP =
        [
          { key: 'PHC', value: 'PHC' },
          { key: 'SAP', value: 'SAP' },
          { key: 'Primavera', value: 'Primavera' },
          { key: 'Sage', value: 'Sage' },
          { key: 'Navision', value: 'Navision' },
          { key: formatMessage({ id: 'other' }), value: formatMessage({ id: 'other' }) },
        ];

    const children = [];
    for (let i = 0; i < softwareERP.length; i++) {
      children.push(<Option key={softwareERP[i].key}>{softwareERP[i].value}</Option>);
    }

    return (
      <ExploreFeaturesWrapper>
        <Container>
          <div className="main-div-talk">
            <Box {...colText}>
              <div className="talk-text-div">
                <div className="text-zone">
                  <div>
                    <Heading content={formatMessage({ id: 'talkToUsSection.title' })} {...this.props.sectionTitle} />
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <Heading content={formatMessage({ id: 'talkToUsSection.subTitle' })} {...this.props.sectionSubTitle} />
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignSelf: 'flex-start',
                  flexDirection: 'column',
                  width: '90%',
                }}>
                  <div>TRUSTED BY</div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    width: '100%',
                  }}>
                    <div>
                      <Image
                        src={ramalhos}
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          opacity: 0.5,
                          cursor: 'pointer',
                        }}
                        onClick={() => window.open('http://www.ramalhos.com', '_blank')}
                    />
                    </div>
                    <div>
                      <Image
                        src={marjos}
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            opacity: 0.5,
                            cursor: 'pointer',
                          }}
                        onClick={() => window.open('https://www.marjos.pt/', '_blank')}
                      />
                    </div>
                    <div>
                      <Image
                        src={safira}
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            opacity: 0.5,
                            cursor: 'pointer',
                          }}
                        onClick={() => window.open('https://safira-fs.com/', '_blank')}
                      />
                    </div>
                    <div>
                      <Image
                        src={longoplano}
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            opacity: 0.5,
                            cursor: 'pointer',
                          }}
                        onClick={() => window.open('http://www.longoplano.pt/', '_blank')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Box>
            <Box {...colForm}>
              <div className="form-talk">
                <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
                alignContent: 'center',
                width: '100%',
                flexDirection: 'column',
              }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    width: '100%',
                  }}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '100%',
                      marginRight: 5,
                    }}>
                      <label>{formatMessage({ id: 'talkToUsSection.firstName' })}</label>
                      <Input
                        id="firstName"
                        inputType="text"
                        isMaterial={false}
                        value={this.state.firstName}
                        onChange={value => this.handleChangeText('firstName', value)}
                      />
                    </div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '100%',
                      marginLeft: 5,
                    }}>
                      <label>{formatMessage({ id: 'talkToUsSection.lastName' })}</label>
                      <Input
                        id="lastName"
                        inputType="text"
                        isMaterial={false}
                        value={this.state.lastName}
                        onChange={value => this.handleChangeText('lastName', value)}
                      />
                    </div>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: 20,
                  }}>
                    <label>{formatMessage({ id: 'talkToUsSection.email' })}</label>
                    <Input
                      id="email"
                      inputType="email"
                      isMaterial={false}
                      value={this.state.email}
                      onChange={value => this.handleChangeText('email', value)}
                />
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: 20,
                  }}>
                    <label>{formatMessage({ id: 'talkToUsSection.companyName' })}</label>
                    <Input
                      id="companyName"
                      inputType="name"
                      isMaterial={false}
                      value={this.state.companyName}
                      onChange={value => this.handleChangeText('companyName', value)}
                    />
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: 20,
                  }}>
                    <label>{formatMessage({ id: 'talkToUsSection.usersNumber' })}</label>
                    <Input
                      id="users_number"
                      style={{ paddingTop: 2, paddingBottom: 2 }}
                      inputType="text"
                      isMaterial={false}
                      value={this.state.users_number}
                      onChange={value => this.handleChangeText('users_number', value)}
                />
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: 20,
                  }}>
                    <div>
                      <label>{formatMessage({ id: 'talkToUsSection.software' })}</label>
                    </div>
                    <Select size="default" value={this.state.selectedSoftware} onChange={this.handleChangeDropdown} style={{ width: '100%' }}>
                      {children}
                    </Select>
                    {this.state.selectedSoftware === formatMessage({ id: 'other' }) ?
                      <div style={{ marginTop: 10 }}>
                        <Input
                          inputType="name"
                          value={this.state.otherSoftware}
                          onChange={value => this.handleChangeText('otherSoftware', value)}
                            />
                      </div>
                        :
                        null
                    }
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      width: '100%',
                      marginTop: 20,
                    }}>
                      <label>{formatMessage({ id: 'talkToUsSection.moreInfo' })}</label>
                      <Input.TextArea
                        autoSize
                        isMaterial={false}
                        value={this.state.moreInfo}
                        onChange={value => this.handleChangeText('moreInfo', value)}
                      />
                    </div>
                  </div>
                  <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: 25,
                }}>
                    <Button
                      type="button"
                      style={{ fontWeight: 700 }}
                      title={formatMessage({ id: 'talkToUsSection.sendButton' })}
                      {...this.props.btnStyle}
                      onClick={this.handleSubmit}
                      isLoading={this.state.loading}
                      disabled={this.state.loading}
                      iconPosition="left"
                  />
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </Container>
      </ExploreFeaturesWrapper>
    );
  }
}

// ServiceSection style props
TalkToUs.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
  menuCustomWraper: PropTypes.object,
};

// ServiceSection default style
TalkToUs.defaultProps = {
  // section header default style
  sectionHeader: {
    mt: ['50px', '50px', '50px', '80px'],
    mb: ['30px', '30px', '30px', '30px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    textAlign: 'left',
    fontSize: '18px',
    fontWeight: '300',
    color: '#0f2137',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'left',
    fontSize: ['24px', '28px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  colForm: {
    width: ['100%', '100%', '45%', '45%'],
  },
  colText: {
    width: ['100%', '100%', '55%', '55%'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '80px'],
    height: ['70px', '80px'],
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: '30px',
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
    mt: '-5px',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    letterSpacing: '-0.020em',
    mb: '10px',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
  menuCustomWraper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  btnStyle: {
    width: '100%',
    minHeight: '45px',
    fontSize: '14px',
    fontWeight: '500',
    borderRadius: 4,
  },
};

export default TalkToUs;
