import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import '../../../src/assets/css/override.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import Image from '../../elements/Image';
import ramalhos from '../../assets/image/saas/companies/ramalhos.webp';
import ramalhos_brasil from '../../assets/image/saas/companies/ramalhos_brasil.webp';
import maisassistencia from '../../assets/image/saas/companies/maisassistencia.webp';
import longoplano from '../../assets/image/saas/companies/longoplano.webp';
import cmc from '../../assets/image/saas/companies/cmc.webp';
import marjos from '../../assets/image/saas/companies/marjos.webp';
import safira from '../../assets/image/saas/companies/safira.webp';

export default class Carousel extends React.Component {
  state = {
    galleryItems: [1, 2, 3, 4, 5, 6, 7].map(i => (<Image
      key={i}
      src={cmc}
      style={{
 maxHeight: 150, marginLeft: 'auto', marginRight: 'auto', opacity: 0.5,
}}/>)),
  }

  responsive = {
    0: { items: 1 },
    500: { items: 1 },
    700: { items: 2 },
    1024: { items: 4 },
  }

  render() {
    return (
      <div
        className="carousel"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
          alignItems: 'center',
      }}>
        <AliceCarousel
          autoPlay
          autoPlayInterval={2500}
          buttonsDisabled
          dotsDisabled
          infinite
          responsive={this.responsive}
    >
          <Image
            src={ramalhos}
            style={{
              maxHeight: 150,
              marginLeft: 'auto',
              marginRight: 'auto',
              opacity: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => window.open('http://www.ramalhos.com', '_blank')}
          />
          <Image
            src={ramalhos_brasil}
            style={{
              maxHeight: 150,
              marginLeft: 'auto',
              marginRight: 'auto',
              opacity: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => window.open('http://www.ramalhosbrasil.com.br', '_blank')}
          />
          <Image
            src={longoplano}
            style={{
              maxHeight: 150,
              marginLeft: 'auto',
              marginRight: 'auto',
              opacity: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => window.open('http://www.longoplano.pt', '_blank')}
          />
          <Image
            src={cmc}
            style={{
              maxHeight: 150,
              marginLeft: 'auto',
              marginRight: 'auto',
              opacity: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => window.open('http://www.cmcid.com', '_blank')}
          />
          <Image
            src={maisassistencia}
            style={{
              maxHeight: 150,
              marginLeft: 'auto',
              marginRight: 'auto',
              opacity: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => window.open('http://www.maisassistencia.pt', '_blank')}
          />
          <Image
            src={marjos}
            style={{
              maxHeight: 150,
              marginLeft: 'auto',
              marginRight: 'auto',
              opacity: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => window.open('http://www.marjos.pt', '_blank')}
          />
          <Image
            src={safira}
            style={{
              maxHeight: 150,
              marginLeft: 'auto',
              marginRight: 'auto',
              opacity: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => window.open('http://www.safira-fs.com', '_blank')}
          />
        </AliceCarousel>
      </div>
    );
  }
}
