import React, { Component, Fragment, forwardRef } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import smoothscroll from 'smoothscroll-polyfill';
import {
  GlobalStyle,
  ContentWrapper,
} from './containers/Saas/saas.style';
import './assets/css/antdOverride.css';
import { DrawerProvider } from './contexts/DrawerContext';
import TalkToUs from './icomponents/TalkToUs/TalkToUs';
import Carousel from './icomponents/Carousel/Carousel';
import { saasTheme } from './theme/saas';
import { ResetCSS } from './assets/css/style';
import Loadable from 'react-loadable';
import Loading from './elements/Animation/Loading';

import ServiceSection from './containers/Saas/ServiceSection';
import PricingSection from './containers/Saas/PricingSection';
import FaqSection from './containers/Saas/FaqSection';

const Navbar = Loadable({ loader: () => import('./containers/Saas/Navbar'), loading: Loading });
const BannerSection = Loadable({ loader: () => import('./containers/Saas/BannerSection'), loading: Loading });
const VisitorSection = Loadable({ loader: () => import('./containers/Saas/VisitorSection'), loading: Loading });
const Footer = Loadable({ loader: () => import('./containers/Saas/Footer'), loading: Loading });
const TimelineSection = Loadable({ loader: () => import('./containers/Saas/TimelineSection'), loading: Loading });
const PrivacyPolicy = Loadable({ loader: () => import('./icomponents/PrivacyPolicy/PrivacyPolicy'), loading: Loading });
const TermsConditions = Loadable({ loader: () => import('./icomponents/TermsConditions/TermsConditions'), loading: Loading });
const ExploreFeatures = Loadable({ loader: () => import('./icomponents/ExploreMore/ExploreFeatures'), loading: Loading });
const ExploreWorkFlowSection = Loadable({ loader: () => import('./icomponents/ExploreMore/ExploreWorkFlow'), loading: Loading });
const CustomizationBody = Loadable({ loader: () => import('./icomponents/Customization/Body'), loading: Loading });
const ReportBody = Loadable({ loader: () => import('./icomponents/Report/Body'), loading: Loading });
const ProjectBody = Loadable({ loader: () => import('./icomponents/Project/Body'), loading: Loading });
const MapBody = Loadable({ loader: () => import('./icomponents/Map/Body'), loading: Loading });
const ScheduleBody = Loadable({ loader: () => import('./icomponents/Schedule/Body'), loading: Loading });
const InvoiceIntegrationBody = Loadable({ loader: () => import('./icomponents/InvoiceIntegration/Body'), loading: Loading });
const ComingSoon = Loadable({ loader: () => import('./icomponents/ComingSoon/ComingSoon'), loading: Loading });
const Drift = Loadable({ loader: () => import('react-driftjs'), loading: () => null });
const FullStory = Loadable({ loader: () => import('react-fullstory'), loading: () => null });

smoothscroll.polyfill();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formatMessage: props.intl.formatMessage,
      politics: false,
      security: false,
      language: props.language,
      elementPricing: {},
      elementFeature: {},
      elementFaq: {},
      updated: false,
    };

    this.myRef = React.forwardRef(<Footer/>);

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const elementFaq = document.getElementById('faq_section');
    const elementFeature = document.getElementById('feature_section');
    const elementPricing = document.getElementById('pricing_section');

    ReactGA.pageview(window.location.pathname);

    const item = localStorage.getItem('scrollTo');
    if (item) {
      localStorage.removeItem('scrollTo');
      switch (item) {
        case '#feature_section':
          window.scroll({ top: Number(elementFeature.offsetTop), left: 0, behavior: 'smooth' });
          break;
        case '#pricing_section':
          window.scroll({ top: Number(elementPricing.offsetTop), left: 0, behavior: 'smooth' });
          break;
        case '#faq_section':
          window.scroll({ top: Number(elementFaq.offsetTop), left: 0, behavior: 'smooth' });
          break;
        default:
          break;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  componentDidUpdate(prevProps) {
    console.log(' DID UPDATE ');
    if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
  }

  handleScroll() {
    this.setState({ updated: !this.state.updated });
  }

  render() {
    const { myRefFuncionalidades } = this;
    const Home = () => (
      <div>
        <BannerSection {...this.props} formatMessage={this.state.formatMessage} language={this.state.language}/>
        <Carousel />
        {/* <FeatureSection formatMessage={this.state.formatMessage}/> */}
        <VisitorSection {...this.props} formatMessage={this.state.formatMessage}/>
        {/* <TargetSection {...this.props} formatMessage={this.state.formatMessage}/> */}
        <ServiceSection {...this.props} formatMessage={this.state.formatMessage}/>
        <TimelineSection {...this.props} formatMessage={this.state.formatMessage}/>
        {/* <WorkflowSection {...this.props} formatMessage={this.state.formatMessage}/> */}
        {/* <PricingSection {...this.props} formatMessage={this.state.formatMessage} /> */}
        <FaqSection formatMessage={this.state.formatMessage}/>
      </div>
    );

    const WrappedMap = () => (
      <div>
        <MapBody {...this.props} formatMessage={this.state.formatMessage}/>
      </div>
    );

    const WrappedCustomization = () => (
      <div>
        <CustomizationBody {...this.props} formatMessage={this.state.formatMessage}/>
      </div>
    );

    const WrappedReport = () => (
      <div>
        <ReportBody {...this.props} formatMessage={this.state.formatMessage}/>
      </div>
    );

    const WrappedProject = () => (
      <div>
        <ProjectBody {...this.props} formatMessage={this.state.formatMessage}/>
      </div>
    );

    const WrappedSchedule = () => (
      <div>
        <ScheduleBody {...this.props} formatMessage={this.state.formatMessage}/>
      </div>
    );
    const WrappedInvoiceIntegration = () => (
      <div>
        <InvoiceIntegrationBody {...this.props} formatMessage={this.state.formatMessage}/>
      </div>
    );

    const WrappedPrivacyPolicy = () => (
      <div>
        <PrivacyPolicy {...this.props} formatMessage={this.state.formatMessage} language={this.state.language}/>
      </div>
    );

    const WrappedTermsConditions = () => (
      <div>
        <TermsConditions {...this.props} formatMessage={this.state.formatMessage} language={this.state.language}/>
      </div>
    );

    const WrappedExploreMore = () => (
      <div>
        <ExploreWorkFlowSection {...this.props} formatMessage={this.state.formatMessage}/>
        <ExploreFeatures {...this.props} formatMessage={this.state.formatMessage}/>
      </div>
    );

    const WrappedComingSoon = () => (
      <div>
        <ComingSoon {...this.props}/>
      </div>
    );

    const WrappedTalkToUs = () => (
      <div>
        <TalkToUs {...this.props} formatMessage={this.state.formatMessage}/>
      </div>
    );

    const seo = {
      title: 'TitanTasks',
      description:
          'A free, online team management platform that adapts to your business. Sign up now!',
      url: 'https://titantasks.com',
    };

    return (
      <ThemeProvider theme={saasTheme}>
        <Fragment>
          {/* <Head>
                    <title>Ride Sharing | A react next landing page</title>
                    <meta name="theme-color" content="#ec5555" />
                    <meta name="Description" content="React next landing page" />

                     Load google fonts
                    <link
                        href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Lato:300,400,700"
                        rel="stylesheet"
                    />
                </Head> */}
          <ResetCSS/>
          <GlobalStyle/>
          <ContentWrapper>
            <FullStory org="QD69A" />
            <Sticky top={0} innerZ={999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar {...this.props} handleScrollTo={this.handleScroll} formatMessage={this.state.formatMessage}/>
              </DrawerProvider>
            </Sticky>
            <Switch>
              <Route path="/privacy-policy" component={WrappedPrivacyPolicy}/>
              <Route path="/terms-conditions" component={WrappedTermsConditions}/>
              <Route path="/explore-more" component={WrappedExploreMore}/>
              <Route path="/coming-soon" component={WrappedComingSoon}/>
              <Route exact path="/talk-to-us" component={WrappedTalkToUs}/>
              <Route exact path="/customization" component={WrappedCustomization}/>
              <Route exact path="/reports" component={WrappedReport}/>
              <Route exact path="/projects" component={WrappedProject}/>
              <Route exact path="/schedule" component={WrappedSchedule}/>
              <Route exact path="/invoice-integration" component={WrappedInvoiceIntegration}/>
              <Route exact path="/map" component={WrappedMap}/>
              <Route exact path="/" component={Home}/>
            </Switch>
            <Drift appId="gvy7tz7e3bai"/>
            <Footer ref={this.myRef} {...this.props} formatMessage={this.state.formatMessage} />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>

    );
  }
}

export default injectIntl(withRouter(App));
