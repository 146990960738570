import { socialTwitter } from 'react-icons-kit/ionicons/socialTwitter';
import { socialFacebook } from 'react-icons-kit/ionicons/socialFacebook';
import { socialYoutube } from 'react-icons-kit/ionicons/socialYoutube';
import { socialDribbbleOutline } from 'react-icons-kit/ionicons/socialDribbbleOutline';
import { socialGithub } from 'react-icons-kit/ionicons/socialGithub';
import { socialGoogleplusOutline } from 'react-icons-kit/ionicons/socialGoogleplusOutline';

export const Faq = [
  {
    id: 1,
    expend: true,
    title: 'customerService',
    description:
      'customerServiceText',
  },
  {
    id: 2,
    title: 'freeVersion',
    description:
      'freeVersionText',
  },
  {
    id: 3,
    title: 'userTab',
    description:
      'userTabText',
  },
  {
    id: 4,
    title: 'newFeature',
    description:
      'newFeatureText',
  },
  {
    id: 4,
    title: 'updates',
    description:
      'updatesText',
  },
];

export const Features = [
  {
    id: 1,
    icon: 'flaticon-strategy',
    title: 'planYourTasks',
    description:
      'planYourTasksDescription',
  },
  {
    id: 2,
    icon: 'flaticon-briefing',
    title: 'easyQuotes',
    description:
      'easyQuotesDescription',
  },
  {
    id: 3,
    icon: 'flaticon-settings',
    title: 'easyToCustomize',
    description:
      'easyToCustomizeDescription',
  },
];

export const Footer_Data = [
  {
    title: 'legal',
    menuItems: [
      {
        url: '/privacy-policy',
        text: 'privacyPolicy',
      },
      {
        url: '/terms-of-service',
        text: 'termsOfService',
      },
      {
        url: '/security-gdpr',
        text: 'security&gdpr',
      },
    ],
  },
];

export const Target = [
  {
    name: 'clean',
    content: 'cleanText',
  },
  {
    name: 'plumb',
    content: 'plumbText',
  },
  {
    name: 'electric',
    content: 'electricText',
  },
  {
    name: 'clime',
    content: 'climeText',
  },
  {
    name: 'other',
    content: 'otherText',
  },
];

export const Service = [
  {
    id: 1,
    icon: 'flaticon-strategy',
    title: 'planYourTasks',
    path: '/explore-more',
    description:
        'planYourTasksDescription',
  },
  {
    id: 2,
    icon: 'flaticon-briefing',
    title: 'easyQuotes',
    path: '/explore-more',
    description:
        'easyQuotesDescription',
  },
  {
    id: 3,
    icon: 'flaticon-settings',
    title: 'easyToCustomize',
    path: '/customization',
    description:
        'easyToCustomizeDescription',
  },
  {
    id: 4,
    icon: 'flaticon-team',
    title: 'schedule',
    path: '/schedule',
    description:
        'scheduleText',
  },
  /* {
    id: 4,
    icon: 'flaticon-team',
    title: 'clients',
    description:
      'clientsText',
  }, */
  {
    id: 5,
    icon: 'flaticon-spaceship',
    title: 'assets',
    path: '/',
    description:
      'assetsText',
  },
  /* {
    id: 6,
    icon: 'flaticon-invoice',
    title: 'invoices',
    description:
      'invoicesText',
  }, */
  {
    id: 7,
    icon: 'flaticon-briefing',
    title: 'reports',
    path: '/reports',
    description:
        'reportsText',
  },
  /* {
    id: 7,
    icon: 'flaticon-briefing',
    title: 'reports',
    description:
      'reportsText',
  }, */
  {
    id: 8,
    icon: 'flaticon-email',
    title: 'mailIntegration',
    path: '/',
    description:
      'mailIntegrationText',
  },
  /* {
    id: 9,
    icon: 'flaticon-office-store',
    title: 'multiStores',
    description:
      'multiStoresText',
  }, */
  {
    id: 10,
    icon: 'flaticon-map',
    title: 'map',
    path: '/map',
    description:
      'mapText',
  },
  {
    id: 11,
    icon: 'flaticon-export',
    title: 'invoiceIntegration',
    path: '/invoice-integration',
    description:
      'invoiceIntegrationText',
  },
];

export const mobile = [
  {
    id: 1,
    icon: 'arrow-right',
    title: 'planYourTasks',
    text: 'report',
  },
  {
    id: 2,
    icon: 'arrow-right',
    text: 'photo',
  },
  {
    id: 3,
    icon: 'arrow-right',
    text: 'material',
  },
  {
    id: 4,
    icon: 'arrow-right',
    text: 'signature',
  },
];
export const Timeline = [
  {
    title: 'createsQuotations',
    description:
        'createsQuotationsText',
  },
  {
    title: 'createsTasks',
    description:
        'createsTasksText',
  },
  {
    title: 'tracking',
    description:
        'trackingText',
  },
  {
    title: 'monthlyMeetings',
    description:
        'monthlyMeetingsText',
  },
];

export const Testimonial = [
  {
    id: 1,
    name: 'Michal Andry',
    designation: 'Ceo of Invission Co.',
    comment:
      'Love to work with this designer in every our future project to ensure we are going to build best prototyping features.',
    social_icon: 'flaticon-instagram',
  },
  {
    id: 2,
    name: 'Roman Ul Oman',
    designation: 'Co-founder of QatarDiaries',
    comment:
      'Impressed with master class support of the team and really look forward for the future. A true passionate team.',
    social_icon: 'flaticon-twitter-logo-silhouette',
  },
];

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '70',
  },
  {
    label: 'Service',
    path: '#service_section',
    offset: '70',
  },
  {
    label: 'Features',
    path: '#feature_section',
    offset: '70',
  },
  {
    label: 'Pricing',
    path: '#pricing_section',
    offset: '70',
  },
  {
    label: 'FAQ',
    path: '#faq_section',
    offset: '70',
  },
];

export const MENU_LEFT_EXPLORE_MORE = [
  {
    label: 'home',
    path: '/',
    offset: '70',
  },
];

export const MENU_LEFT_ITEMS = [
  /* {
    label: 'home',
    path: '#banner_section',
    offset: '70',
  }, */
  /* {
    label: 'service',
    path: '#service_section',
    offset: '70',
  }, */
  {
    label: 'features',
    path: '#feature_section',
    offset: '70',
  },
  /* {
    label: 'pricing',
    path: '#pricing_section',
    offset: '70',
  }, */
  {
    label: 'faq',
    path: '#faq_section',
    offset: '70',
  },
  {
    label: 'exploreMore',
    path: '/explore-more',
    offset: '70',
    staticLink: true,
  },
  /* {
    label: 'Blog',
    path: 'https://medium.com/titantasks',
    offset: '70',
    staticLink: true,
  }, */
];

export const MENU_LEFT_ITEMS_EXPLORE = [
  {
    label: 'Home',
    path: '/',
    offset: '70',
    staticLink: true,
  },
  {
    label: 'platform',
    path: '#service_section',
    offset: '70',
  },
  {
    label: 'features',
    path: '#feature_section',
    offset: '70',
  },
  {
    label: 'Blog',
    path: 'https://medium.com/titantasks',
    offset: '70',
    staticLink: true,
  },
];
export const MENU_LEFT_ITEMS_POLICY = [
  {
    label: 'Home',
    path: '/',
    offset: '70',
    staticLink: true,
  },
];

export const MENU_RIGHT_ITEMS = [
  {
    label: 'login',
    path: '#',
    offset: '70',
  },
  /* {
    label: 'signUp',
    path: '#',
    offset: '70',
  }, */
];

export const MONTHLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For Small teams or group who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Business Account',
    description: 'For Mediums teams or group who need to build website ',
    price: '$9.87',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$12.98',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$12.98',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For a single client or team who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Business Account',
    description: 'For Small teams or group who need to build website ',
    price: '$6.00',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Unlimited secure storage',
      },
      {
        content: '2,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: '24/7 phone support',
      },
      {
        content: '50+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$9.99',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '3,000s of Templates Ready',
      },
      {
        content: 'Advanced branding',
      },
      {
        content: 'Knowledge base support',
      },
      {
        content: '80+ Webmaster Tools',
      },
    ],
  },
];

export const SOCIAL_PROFILES = [
  {
    icon: socialFacebook,
    url: 'https://www.facebook.com/titantasks/',
  },
  {
    icon: socialYoutube,
    url: 'https://www.youtube.com/channel/UCbPK3u2r7KyMkC6RuypP2dA',
  },
  {
    icon: socialTwitter,
    url: '#',
  },
  {
    icon: socialGithub,
    url: '#',
  },
  {
    icon: socialGoogleplusOutline,
    url: '#',
  },
];
